import React, { useEffect, useState } from "react";
import { updateSettings, uploadFile } from "../services/api";

export default function SettingAQI(props) {
    const [data, setData] = useState(null);
    const [files, setFiles] = useState([]);
    const [isAlert, setIsAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");

    const handleSubmit = () => {
        var payload = {}
        payload[props.name] = {
            building: sessionStorage.getItem("building"),
            message_outdoor: data?.message_outdoor,
            message_indoor: data?.message_indoor,
        }
        updateSettings(payload,
            (res) => {
                setIsAlert(true)
                setAlertTitle(`update info success`)
            },
            (err) => { console.log(err) }
        )

        for (const [key, value] of Object.entries(files)) {
            if (value == undefined) continue
            if (props?.presigned && `${props?.name}_${key}` in props?.presigned) {
                var formData = new FormData();
                formData.append("link", props?.presigned[`${props?.name}_${key}`]);
                formData.append("file", value);

                uploadFile(formData,
                    (response) => {
                        setIsAlert(true)
                        setAlertTitle(`${response?.data}`)
                    },
                    (error) => console.log(error)
                )
            }
        }
    }
    useEffect(() => {
        if (props.data && props.name in props.data) {
            let message_outdoor = props?.data[props.name].message_outdoor || null
            let message_indoor = props?.data[props.name].message_indoor || null
            setData({ message_outdoor: message_outdoor, message_indoor: message_indoor })
        }
    }, [props.data])
    return (
        <>
            {isAlert && <div class="alert alert-warning p-1 px-3 text-capitalize" role="alert" style={{ fontSize: "0.8rem" }}>
                {alertTitle}
            </div>}
            <form id={props.name}>
                <div class="form-row text-start d-flex mb-2">
                    <div class="form-group col-md-2 mb-2 px-1">
                        <label class="fw-bold text-capitalize">{props.display_name}</label>
                    </div>
                    <div class="form-group col-md-10 px-1">
                        <div class="form-row text-start d-flex mb-2">
                            <div class="form-group col-md-6 mb-2 px-1">
                                <label for="MessageOutdoor">Outdoor Message</label>
                                <input type="text" class="form-control" id="MessageOutdoor" placeholder="Message"
                                    defaultValue={data?.message_outdoor}
                                    onChange={(e) => setData({ ...data, message_outdoor: e.target.value })}
                                />
                            </div>
                            <div class="form-group col-md-6 mb-2 px-1">
                                <label for="MessageIndoor">Indoor Message</label>
                                <input type="text" class="form-control" id="MessageIndoor" placeholder="Message"
                                    defaultValue={data?.message_indoor}
                                    onChange={(e) => setData({ ...data, message_indoor: e.target.value })}
                                />
                            </div>
                        </div>
                        <div class="form-row text-start d-flex mb-2">
                            <div class="form-group col-md-6 mb-2 px-1">
                                <label for="MainBgOutdoor">Main Background Outdoor</label>
                                <input type="file" class="form-control" id="MainBgOutdoor" accept="video/mp4"
                                    onChange={(e) => { setFiles({ ...files, main_background_outdoor: e.target.files[0] }) }}
                                />
                            </div>
                            <div class="form-group col-md-6 mb-2 px-1">
                                <label for="MainBgIndoor">Main Background Indoor</label>
                                <input type="file" class="form-control" id="MainBgIndoor" accept="video/mp4"
                                    onChange={(e) => { setFiles({ ...files, main_background_indoor: e.target.files[0] }) }}
                                />
                            </div>
                        </div>
                        <div class="form-row text-start d-flex mb-2">
                            <div class="form-group col-md-6 mb-2 px-1">
                                <label for="BannerOutdoor">Banner Outdoor</label>
                                <input type="file" class="form-control" id="BannerOutdoor" accept="video/mp4"
                                    onChange={(e) => { setFiles({ ...files, banner_outdoor: e.target.files[0] }) }}
                                />
                            </div>
                            <div class="form-group col-md-6 mb-2 px-1">
                                <label for="BannerIndoor">Banner Indoor</label>
                                <input type="file" class="form-control" id="BannerIndoor" accept="video/mp4"
                                    onChange={(e) => { setFiles({ ...files, banner_indoor: e.target.files[0] }) }}
                                />
                            </div>
                        </div>
                        <div class="form-row text-start d-flex mb-2">
                            <div class="form-group col-md-6 mb-2 px-1">
                                <label for="TVOutdoor">TV Outdoor</label>
                                <input type="file" class="form-control" id="TVOutdoor" accept="video/mp4"
                                    onChange={(e) => { setFiles({ ...files, tv_outdoor: e.target.files[0] }) }}
                                />
                            </div>
                            <div class="form-group col-md-6 mb-2 px-1">
                                <label for="TVIndoor">TV Indoor</label>
                                <input type="file" class="form-control" id="TVIndoor" accept="video/mp4"
                                    onChange={(e) => { setFiles({ ...files, tv_indoor: e.target.files[0] }) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <button class="btn btn-primary mt-3 w-100" onClick={handleSubmit}>Submit</button>
        </>
    );
}