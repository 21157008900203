import React, { useEffect, useState } from "react";
import Logo from "../assets/icons/logo_color.svg"
import { authApi } from "../services/api";
import { redirectTo } from "../services/helper";

export default function CreateAccount() {
    const [isAlert, setIsAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");

    const handleCreateAccount = () => {
        let username = document.getElementById("username").value
        let password = document.getElementById("password").value
        let building = document.getElementById("building").value
        let role = document.getElementById("role").value
        let displaytype = document.getElementById("displaytype").value
        let system = document.getElementById("system").value

        if (username == "" || password == "" || building == "" || role == "") {
            setAlertTitle("Enter the input in all field!")
            setIsAlert(true)
        } else {
            var payload = {
                username: username,
                password: password,
                building: building,
                role: role,
                display_type: displaytype,
                system: system
            }
            authApi.createAccount(
                payload,
                (res) => {
                    if (res?.message == "success") {
                        document.getElementById("username").value = ""
                        document.getElementById("password").value = ""
                        document.getElementById("building").value = ""
                        document.getElementById("role").value = "user"
                        document.getElementById("displaytype").value = "banner"
                        document.getElementById("system").value = "both"
                    }
                    setAlertTitle(res?.message)
                    setIsAlert(true)
                },
                (err) => { console.log(err) })
        }
    }
    useEffect(() => {
        let myRole = sessionStorage.getItem("role")
        if (myRole != "admin" && myRole != "superadmin") {
            sessionStorage.removeItem("role")
            sessionStorage.removeItem("building")
            redirectTo("/")
        }
    }, [])
    return (
        <div class="d-flex flex-column justify-content-center align-items-center bg-dark-cyan" style={{ width: "100vw", height: "100vh" }}>
            <img class="mb-4" alt="logo" src={Logo} width="400px" />
            <h3 class="text-white mb-4">Create New Account</h3>
            <div style={{ height: "3rem" }}>
                {isAlert && <div class="alert alert-warning p-1 px-3 text-capitalize" role="alert" style={{ fontSize: "0.8rem" }}>
                    {alertTitle}
                </div>}
            </div>
            <div class="d-flex flex-column justify-content-start" style={{ width: "500px", color: "#FFFFFF" }}>
                <form style={{ width: "100%" }}>
                    <div class="form-group row mb-3">
                        <label class="col-sm-3 col-form-label" for="username">Username</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="username" placeholder="Username" required />
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-sm-3 col-form-label" for="password">Password</label>
                        <div class="col-sm-9">
                            <input type="password" class="form-control" id="password" placeholder="Password" required />
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-sm-3 col-form-label" for="building">Building</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="building" placeholder="Building" required />
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-sm-3 col-form-label" for="role">Role</label>
                        <div class="col-sm-9">
                            <select class="form-control" id="role">
                                <option value="user">User</option>
                                {sessionStorage.getItem("role") == "superadmin" && <option value="admin">Admin</option>}
                            </select>
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-sm-3 col-form-label" for="role">Display Type</label>
                        <div class="col-sm-9">
                            <select class="form-control" id="displaytype">
                                <option value="banner">Banner</option>
                                <option value="tv">TV</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <label class="col-sm-3 col-form-label" for="role">System</label>
                        <div class="col-sm-9">
                            <select class="form-control" id="system">
                                <option value="both">Both</option>
                                <option value="aqi">Air Quality</option>
                                <option value="energy">Energy</option>
                            </select>
                        </div>
                    </div>
                </form>
                <div class="w-100 d-flex justify-content-end">
                    <button class="btn btn-outline-light" onClick={handleCreateAccount}>Submit</button>
                </div>
                <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
                </div>
            </div>
        </div>
    )
}