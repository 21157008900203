import axios from "axios";

const BASE_URL = "https://dashboard.smartbuildingplatform.ai";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "content-Type": "application/json",
  },
  credentials: "same-origin",
});
const uploadApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'content-Type': 'multipart/form-data'
  },
  credentials: "same-origin",
});

export const authApi = {
  login: (payload, callback, onRejected) => {
    api
      .post("/api/authentication", payload)
      .then((res) => {
        if ("id" in res?.data) {
          sessionStorage.setItem("user_id", res.data.id)
          sessionStorage.setItem("building", res.data.building)
          sessionStorage.setItem("role", res.data.role)
          callback(res.data);
        } else {
          callback("failed");
        }
      })
      .catch((err) => onRejected(err));
  },
  createAccount: (payload, callback, onRejected) => {
    api
      .post("/api/register", payload)
      .then((res) => {
        callback(res?.data)
      })
      .catch((err) => onRejected(err));
  },
  changePassword: (payload, callback, onRejected) => {
    api
      .post("/api/changepassword", payload)
      .then((res) => {
        callback(res?.data)
      })
      .catch((err) => onRejected(err));
  },
}

export const getDataAPI = (payload, callback, onRejected) => {

  api.post("/api/banner", payload)
    .then((response) => {
      if (payload.type == "Energy") {
        var arr = []
        Object.keys(response.data.energy).forEach(key => {
          if (typeof response.data.energy[key] == "string" && response.data.energy[key].startsWith("https://")) {
            arr.push(key)
          }
        })
        var count = 0
        arr.forEach(item => {
          fetch(response.data.energy[item])
            .then(response => response.blob())
            .then(function (myBlob) {
              var objectURL = URL.createObjectURL(myBlob);
              response.data.energy[item] = objectURL;
              count += 1
              if (count == arr.length) {
                callback(response?.data)
              }
            })
        })

      }
      if (payload.type == "AQI") {
        let sites = ['outdoor', 'indoor']
        sites.forEach((site, site_index) => {
          var arr = []
          Object.keys(response.data[site]).forEach(key => {
            if (typeof response.data[site][key] == "string" && response.data[site][key].startsWith("https://")) {
              arr.push(key)
            }
          })
          var count = 0
          arr.forEach((item) => {
            fetch(response.data[site][item])
              .then(response => response.blob())
              .then(function (myBlob) {
                var objectURL = URL.createObjectURL(myBlob);
                response.data[site][item] = objectURL;
                count += 1
                if (site_index == sites.length - 1 && count == arr.length) {
                  callback(response?.data)
                }
              })
          })
        })
      }
    })
    .catch((err) => onRejected(err));
}
export const getSetting = (callback, onRejected) => {
  api.post("/api/getsettings", { building: sessionStorage.getItem("building") })
    .then((response) => {
      callback(response?.data);
    })
    .catch((err) => onRejected(err));
}
export const updateSettings = (payload, callback, onRejected) => {
  api.post("/api/updatesettings", payload)
    .then((response) => {
      callback(response);
    })
    .catch((err) => onRejected(err));
}
export const getUploadLink = (callback, onRejected) => {
  api.post("/api/getpresigned", { building: sessionStorage.getItem("building") })
    .then((response) => {
      callback(response);
    })
    .catch((err) => onRejected(err));
}
export const uploadFile = (payload, callback, onRejected) => {
  uploadApi.put("/api/uploadFile", payload)
    .then((response) => {
      callback(response);
    })
    .catch((err) => onRejected(err));
}
export const uploadSvg = (payload, callback, onRejected) => {
  uploadApi.put("/api/uploadSvg", payload)
    .then((response) => {
      callback(response);
    })
    .catch((err) => onRejected(err));
}
