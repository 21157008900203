import React, { useEffect, useState } from "react";
import { redirectTo } from "../services/helper";
import BottomBarAQI from "./bottombar_aqi";
import BottomBarEnergy from "./bottombar_energy";

export default function MainBottomBarTV(props) {
    const [toggle, setToggle] = useState(true);

    useEffect(() => {
        if (!sessionStorage.getItem("building")) redirectTo("/")
        const interval = setInterval(() => {
            setToggle(toggle => !toggle)
        }, props.interval);
        return () => clearInterval(interval);
    }, [])

    return (
        <div class="d-flex flex-column overflow-hidden" style={{
            width: "1080px",
            height: "1920px",
            backgroundColor: "#C4C4C4"
        }}>
            <div style={{ width: "1080px", height: "1720px" }} />
            <div style={{ width: "1080px", height: toggle ? "200px": "0px", visibility: toggle ? "visible":"hidden" }}>
                <BottomBarAQI />
            </div>
            <div style={{ width: "1080px", height: !toggle ? "200px": "0px", visibility: !toggle ? "visible":"hidden" }}>
                <BottomBarEnergy />
            </div>
        </div>
    )
}
