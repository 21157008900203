import React, { useEffect, useState } from "react";
import LocationIcon from "../assets/icons/location.png"
import { getDataAPI } from "../services/api";
import { limitCharacter } from "../services/helper";
import BgVideo from "../components/bg_video";

function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}

export default function BottomBarAQI() {
    const [data, setData] = useState([]);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);

    const loadData = () => {
        getDataAPI({ type: "AQI", "building": sessionStorage.getItem("building") }, (res) => {
            setData(res)
        }, (err) => {
            console.log(err)
        })
        let ddate = new Date();
        setHour(pad(ddate.getHours()))
        setMinute(pad(ddate.getMinutes()))
    }

    useEffect(() => {
        loadData()
        const interval = setInterval(async () => {
            loadData(); // API call
        }, 60000);
    }, [])

    return (
        <>
            {data && <div class="d-flex overflow-hidden" style={{
                width: "1080px",
                height: "200px",
            }}>
                <div id="banner" class="container-fluid bg-black" style={{ color: "#ffffff" }}>
                    <div class="row h-100" style={{ padding: 0 }}>
                        <div class="align-items-center h-100 p-2" style={{ width: "12%" }}>
                            <h3 class="mt-3 mb-4" style={{ fontWeight: "bold" }}>คุณภาพอากาศ</h3>
                            <h1 class="pt-2 mb-0">{hour}:{minute}</h1>
                            <img alt="logo" src={data?.logo} height="14px" />
                        </div>
                        <div id="outdoor" class="align-items-center h-100 p-0" style={{ width: "44%" }}>
                            <div class="d-flex justify-content-start box-video-bg-200">
                                {data?.outdoor?.tv &&
                                    <BgVideo source={data?.outdoor?.tv} bottom_bar />
                                }
                                <div class="row h-100 w-100" style={{ position: "absolute" }}>
                                    <div class="d-flex justify-content-center align-items-center m-0 mt-4 p-0">
                                        <div style={{ height: "100px" }}>
                                            {limitCharacter(data?.outdoor?.message, 15).map(line => <h1 class="bottombar-energy-message w-100" style={{ paddingLeft: "7rem" }} >{line}</h1>)}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-baseline mt-2" style={{ height: "40px" }}>
                                        <div class="d-flex pt-2" style={{ paddingLeft: "11.5rem" }}>
                                            <img alt="" src={LocationIcon} height="30" />
                                            <span style={{ fontSize: "1.25rem", fontWeight: "500", paddingTop: ".4rem" }}>{data?.outdoor?.location}</span>
                                        </div>
                                        <span style={{ fontSize: "2rem", fontWeight: "400" }}>{data?.outdoor?.temp}°</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="indoor" class="p-0" style={{ width: "44%" }}>
                            <div class="d-flex justify-content-start box-video-bg-200" >
                                {data?.indoor?.tv &&
                                    <BgVideo source={data?.indoor?.tv} bottom_bar />
                                }
                                <div class="row h-100 w-100" style={{ position: "absolute" }}>
                                    <div class="d-flex justify-content-center align-items-center m-0 mt-4 p-0">
                                        <div style={{ height: "100px" }}>
                                            {limitCharacter(data?.indoor?.message, 15).map(line => <h1 class="bottombar-energy-message w-100" style={{ paddingLeft: "7rem" }} >{line}</h1>)}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-baseline mt-2" style={{ height: "40px" }}>
                                        <div class="d-flex pt-2" style={{ paddingLeft: "11.5rem" }}>
                                            <img alt="" src={LocationIcon} height="30" />
                                            <span style={{ fontSize: "1.25rem", fontWeight: "500", paddingTop: ".4rem" }}>{data?.indoor?.location}</span>
                                        </div>
                                        <span style={{ fontSize: "2rem", fontWeight: "400" }}>{data?.indoor?.temp}°</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}