import React, { useEffect, useState } from "react";
import { updateSettings, uploadFile } from "../services/api";

export default function SettingEnergy(props) {
    const [files, setFiles] = useState([]);
    const [isAlert, setIsAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");

    const handleSubmit = () => {
        var payload = {}
        payload["energy"] = {
            building: sessionStorage.getItem("building"),
            energy_message: document.getElementById("EnergyMessage").value,
            energy_keyword_1: document.getElementById("EnergyKeyword1").value,
            energy_keyword_2: document.getElementById("EnergyKeyword2").value,
        }
        updateSettings(payload,
            (res) => {
                setIsAlert(true)
                setAlertTitle(`update info success`)
            },
            (err) => { console.log(err) }
        )

        for (const [key, value] of Object.entries(files)) {
            if (value == undefined) continue
            if (props?.presigned && `energy_${key}` in props?.presigned) {
                var formData = new FormData();
                formData.append("link", props?.presigned[`energy_${key}`]);
                formData.append("file", value);

                uploadFile(formData,
                    (response) => {
                        setIsAlert(true)
                        setAlertTitle(`${response?.data}`)
                    },
                    (error) => console.log(error)
                )
            }
        }
    }
    useEffect(() => {
        if (props?.data) {
            document.getElementById("EnergyMessage").value = props?.data.energy_message
            document.getElementById("EnergyKeyword1").value = props?.data.energy_keyword_2
            document.getElementById("EnergyKeyword2").value = props?.data.energy_keyword_1
        }
    }, [props.data])
    return (
        <>
            {isAlert && <div class="alert alert-warning p-1 px-3 text-capitalize" role="alert" style={{ fontSize: "0.8rem" }}>
                {alertTitle}
            </div>}
            <form>
                <div class="form-row text-start mb-2 d-flex">
                    <div class="form-group col-md-6 mb-2 px-1">
                        <label for="EnergyMessage">Energy Message</label>
                        <input type="text" class="form-control" id="EnergyMessage" placeholder="Message" />
                    </div>
                </div>
                <div class="form-row text-start mb-2 d-flex">
                    <div class="form-group col-md-6 mb-2 px-1">
                        <label for="MainBg">Main Background</label>
                        <input type="file" class="form-control" id="MainBg" accept="video/mp4"
                            onChange={(e) => { setFiles({ ...files, main_background: e.target.files[0] }) }}
                        />
                    </div>
                    <div class="form-group col-md-6 mb-2 px-1">
                        <label for="TvBg">TV Background</label>
                        <input type="file" class="form-control" id="TvBg" accept="video/mp4"
                            onChange={(e) => { setFiles({ ...files, tv_background: e.target.files[0] }) }}
                        />
                    </div>
                </div>
                <div class="form-row text-start mb-2 d-flex">
                    <div class="form-group col-md-6 mb-2 px-1">
                        <label for="EnergyKeyword1">Keyword-1</label>
                        <input type="text" class="form-control" id="EnergyKeyword1" placeholder="Keyword" />
                    </div>
                    <div class="form-group col-md-6 mb-2 px-1">
                        <label for="EnergyKeyword2">Keyword-2</label>
                        <input type="text" class="form-control" id="EnergyKeyword2" placeholder="Keyword" />
                    </div>
                </div>
                <div class="form-row text-start mb-2 d-flex">
                    <div class="form-group col-md-6 mb-2 px-1">
                        <label for="Banner1">Banner Smile Bar-1</label>
                        <input type="file" class="form-control" id="Banner1" accept="video/mp4"
                            onChange={(e) => { setFiles({ ...files, banner_1: e.target.files[0] }) }}
                        />
                    </div>
                    <div class="form-group col-md-6 mb-2 px-1">
                        <label for="Banner2">Banner Smile Bar-2</label>
                        <input type="file" class="form-control" id="Banner2" accept="video/mp4"
                            onChange={(e) => { setFiles({ ...files, banner_2: e.target.files[0] }) }}
                        />
                    </div>
                </div>
                <div class="form-row text-start mb-2 d-flex">
                    <div class="form-group col-md-6 mb-2 px-1">
                        <label for="TV1">TV Smile Bar-1</label>
                        <input type="file" class="form-control" id="TV1" accept="video/mp4"
                            onChange={(e) => { setFiles({ ...files, tv_1: e.target.files[0] }) }}
                        />
                    </div>
                    <div class="form-group col-md-6 mb-2 px-1">
                        <label for="TV2">TV Smile Bar-2</label>
                        <input type="file" class="form-control" id="TV2" accept="video/mp4"
                            onChange={(e) => { setFiles({ ...files, tv_2: e.target.files[0] }) }}
                        />
                    </div>
                </div>
            </form>
            <button class="btn btn-primary mt-3" onClick={handleSubmit}>Submit</button>
        </>
    );
}