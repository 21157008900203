import React, { useState } from "react";
import Logo from "../assets/icons/logo_color.svg"
import { authApi } from "../services/api";
import { redirectTo } from "../services/helper";

export default function SignIn() {
    const [isAlert, setIsAlert] = useState(false);

    const handleSignIn = () => {
        var username = document.getElementById("username").value;
        var password = document.getElementById("password").value;
        authApi.login({ username: username, password: password },
            (res) => {
                console.log(res)
                if(res == "failed") setIsAlert(true)
                else {
                    if(res?.system == 'both') redirectTo(`/${res.display_type}`)
                    else redirectTo(`/${res.display_type}/${res.system}`)
                }
            },
            (err) => { console.log(err) })
    }
    return (
        <div class="d-flex flex-column justify-content-center align-items-center bg-dark-cyan" style={{ width: "100vw", height: "100vh" }}>
            <img class="mb-4" alt="logo" src={Logo} width="400px" />
            <div style={{ height: "3rem" }}>
                {isAlert && <div class="alert alert-warning p-1 px-3" role="alert" style={{ fontSize: "0.8rem" }}>
                    Username or Password Not Correct
                </div>}
            </div>
            <div class="d-flex flex-column justify-content-start" style={{ width: "500px", color: "#FFFFFF" }}>
                <form style={{ width: "100%" }}>
                    <div class="form-group row mb-3">
                        <label class="col-sm-3 col-form-label" for="username">Username</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="username" placeholder="Username" />
                        </div>
                    </div>
                    <div class="form-group row mb-4">
                        <label class="col-sm-3 col-form-label" for="password">Password</label>
                        <div class="col-sm-9">
                            <input type="password" class="form-control" id="password" placeholder="Password" />
                        </div>
                    </div>
                </form>
                <div class="w-100 d-flex justify-content-end">
                    <button class="btn btn-outline-light" onClick={handleSignIn}>Sign In</button>
                </div>
            </div>
        </div>
    )
}